import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import rightArrow from "../assets/img/right-arrow.png";
import gadsShoes from "../assets/img/gads-shoes.png"
import result from "../assets/img/result-proud.png"
import bottom from "../assets/img/our-partners.png"
import famale from "../assets/img/female-woman.png"
import mobileWeb from "../assets/img/mobile-web-ad.png"
import clothingClothes from "../assets/img/clothing-clothes.png"

import digital1 from "../assets/img/digital1.png"
import digital2 from "../assets/img/digital2.png"
import digital3 from "../assets/img/digital3.png"
import metaads from "../assets/img/meta-ads-logo.png"
import linkedinads from "../assets/img/linkedin-logo-ads.png"
import criteo   from "../assets/img/criteo-ads-logo.png"
import googleads  from "../assets/img/new-ads-logo.png"
import tiktokads  from "../assets/img/tiktok-ads-logo.png"
import twitterads  from "../assets/img/twitter-ads-logo.png"

import balance from "../assets/img/balance-time-money.png"
import achievement from "../assets/img/achievement-female.png"
import videoSearch from "../assets/img/video-search.png"
import cv from "../assets/img/cv-curriculum.png"
import maleMan from "../assets/img/male-man.png"
import business from "../assets/img/business-idea.png"
import work from "../assets/img/work-preformance.png"
import Teams from "../components/teams/google-team"
import SSS from "../components/sss/gads-sss"
import Companys from "../components/google-ads-referances"
import checkModal from "../assets/img/check-modal.png";
import Checkup from "../components/checkup"
import fulla from "../assets/img/companys/39.jpg"
import liva from "../assets/img/companys/17.png"

class Gads extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focus: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display, focus: !this.state.focus });
        document.querySelector('.check-modal').classList.add('active-modal');

    }
    closeModal() {
        this.setState({ display: !this.state.display, focus: !this.state.focus })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
            }
            else {
                document.querySelector('.status').innerHTML = "Bir hata oluştu.";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="Digital Ads" description="Belirlediğiniz bütçe dahilinde ürün ve hizmetlerinizi doğru kitleye, en etkili yollardan tanıtıyoruz." />
                <div className="seo-service gads container">
                    <div className="seo-service__head" style={{ marginBottom: 70 }}>
                        <div className="col-md-6 noPadding">
                            <h1>Digital Ads</h1>
                            <span className="red-head">HİZMETLER</span>
                            <p>Belirlediğiniz bütçe dahilinde ürün ve hizmetlerinizi doğru kitleye, en etkili yollardan tanıtıyoruz.</p>
                            <input type="text" className="pink-input" placeholder="Websitenizi giriniz." value={this.state.value} onChange={this.handleChange} />
                            <button className="red-button" id="gads-button" onClick={(e) => this.showModal()}>Size özel teklif alın <img alt="alt text" src={rightArrow}></img></button>
                        </div>
                        <div style={{ marginTop: 20 }}><img alt="alt text" className="gservices" src={bottom} /></div>

                        <div className="col-md-6 gads-right" style={{ paddingLeft: 130 }}><img alt="alt text" src={gadsShoes} /> </div>

                    </div>
                    <div className="results-matter container" style={{ margin: '-50px 0 20px' }}>
                        <h2 className="py60" >Digital Ads</h2>
                        <div className="container" style={{ margin: '-30px 0 3px' }}>
                            <div className="col-md-4 matter text-center">
                                <div>
                                    <p style={{ fontSize: 22, fontWeight:600 }}>Performans<br />Pazarlama</p>
                                    <img className="digital_1" alt="alt text" src={digital1} />
                                    <p style={{ fontSize: 19, color:"#595959"}}>Google Ads, YouTube Ads,<br /> Criteo, Yandex, Bing</p>
                                </div>
                            </div>
                            <div className="col-md-4 matter text-center">
                                <div>
                                    <p style={{ fontSize: 22, fontWeight:600 }}>Sosyal Medya<br />Reklamları</p>
                                    <img alt="alt text" src={digital2} />
                                    <p style={{ fontSize: 19, color:"#595959"}}>Facebook&Instagram Ads,<br /> Linkedin Ads, Twitter Ads,<br />  Tiktok Ads,Pinterest Ads</p>
                                </div>
                            </div>
                            <div className="col-md-4 matter text-center">
                                <div>
                                    <p style={{ fontSize: 22, fontWeight:600 }}>Digital Medya Planlama<br />Satın Alma Platformları</p>
                                    <img alt="alt text" src={digital3} />
                                    <p style={{ fontSize: 19, color:"#595959"}}>Spotify, TagOn Taboola,<br /> Admatic vs</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="studies">
                        <h2 style={{ marginBottom: 10 }}>Markalarımızı hedeflerine ulaştırdık, <br />gurur duyduğumuz rakamlara ulaştık.</h2>
                        <div>
                            <span className="red-head">BAŞARI HİKAYELERİ</span>
                        </div>
                    </div>
                    <div className="clients-customers">
                        <div>
                            <span>%120</span>
                            <p>Toplam sipariş sayısı artışı.</p>
                            <Link to="/basari-hikayeleri/liva-pastacilik/">Çalışmanın detaylarına göz atın</Link><br />
                            <img alt="alt text" src={liva}></img>
                        </div>
                        <div>
                            <span>%114.7</span>
                           
                            <p> E-ticaret Dönüşüm Oranında artış.</p>
                            <Link to="/basari-hikayeleri/fullamoda/">Çalışmanın detaylarına göz atın</Link><br />
                            <img alt="alt text" src={fulla}></img>
                        </div>
                        <img alt="alt text" className="result-img" src={result} />
                    </div>
                    <div className="results-matter container py60">
                        <h2 className="py60">Fark yaratan sonuçlar.</h2>
                        <div className="results-matter_box">
                            <div className="col-md-4 matter">
                                <div>
                                    <img alt="alt text" src={famale} />
                                    <h3>Website Trafiğinden Yararlanın</h3>
                                    <p>İnsanları websitenize yönlendiren online reklamlarla online satış ve rezervasyon oranlarını veya e-posta listesi aboneliklerini artırın.</p>
                                </div>
                            </div>
                            <div className="col-md-4 matter">
                                <div>
                                    <img alt="alt text" src={mobileWeb} />
                                    <h3>Telefonla Daha Fazla Aranın</h3>
                                    <p>Telefon numaranızı ön plana çıkaran ve tıklayarak aramayı mümkün kılan online reklamlarla telefonla aranma oranlarınızı artırın.</p>
                                </div>
                            </div>
                            <div className="col-md-4 matter">
                                <div>
                                    <img alt="alt text" src={clothingClothes} />
                                    <h3>Mağaza Ziyaretlerini Artırın</h3>
                                    <p>İnsanların işletmenizi haritada bulmalarına yardımcı olan online reklamlarla mağazanızı daha fazla müşterinin ziyaret etmesini sağlayın.</p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="results-matter container py60">
                        <h2 >Partnerliklerimiz</h2>
                        <p style={{ fontSize: 22, textAlign:"center", maxWidth:850, marginBottom: 50}}>Global ve TR ekipleri ile hızlı temas kurarak reklam planlaması ve performans takibi yaptığımız iş ortaklarımızdan bazıları</p>
                        <div className="results-matter_box" style={{ maxWidth:700}}>
                            <div className="col-md-4">
                                <div>
                                    <img class="sm-ikons" style={{ marginBottom: 20, maxHeight: 55}} src={criteo} alt="Criteo Ads" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img class="sm-ikons" style={{ marginBottom: 20, maxHeight: 65}} src={linkedinads} alt="Linkedin Ads" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img class="sm-ikons" style={{ marginBottom: 20, maxHeight: 110}} src={metaads} alt="Meta Ads" />
                                </div>
                            </div>
                        </div>
                        <div className="results-matter_box" style={{ maxWidth:500}}>
                            <div className="col-md-4">
                                <div>
                                    <img class="sm-ikons1" style={{ marginBottom: 20, maxHeight: 100}} src={googleads} alt="Google Ads" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img class="sm-ikons1" style={{ marginBottom: 20, maxHeight: 100}} src={tiktokads} alt="Tiktok Ads" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img class="sm-ikons1" style={{ marginBottom: 20, maxHeight: 100}} src={twitterads} alt="Meta Ads" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="seo-referances py60 container">
                        <h2>Digital Ads Referanslarımız</h2>
                        <p>Öne çıkan referans markalar.</p>
                        <Companys />
                    </div>


                    <div className="container manage-google py60" style={{ margin: 0 }}>
                        <h2>Digital Ads yönetimi için <br />neden bizi seçmelisiniz?</h2>
                        <p className="manage-google_p">Digital Ads konusunda uzman ekibimizle işinizi büyütmeniz için destek sunuyoruz.</p>
                        <div>
                            <div className="col-md-6 manage-google_item">
                                <img alt="alt text" src={balance} />
                                <div>
                                    <h3>İleri Seviyede Uzmanlarla Çalışma</h3>
                                    <p>Online reklamlarınızı stajyer veya az deneyimli kişiler değil, alanında uzman bir ekip yönetir.</p>
                                </div>
                            </div>
                            <div className="col-md-6 manage-google_item">
                                <img alt="alt text" src={achievement} />
                                <div>
                                    <h3>Değer Oluşturma</h3>
                                    <p>Yatırım geri dönüşünüzü artırmak için etkili çalışmaya odaklanır ve belirlediğimiz KPI’ları sürekli olarak ölçümleriz.</p>
                                </div>
                            </div>
                            <div className="col-md-6 manage-google_item">
                                <img alt="alt text" src={videoSearch} />
                                <div>
                                    <h3>Etkili İletişim Yöntemleri</h3>
                                    <p>Reklam kampanyalarının performansını gözden geçirmek ve yeni fikirleri tartışmak için sizinle düzenli olarak görüşmeler gerçekleştiririz.</p>
                                </div>
                            </div>
                            <div className="col-md-6 manage-google_item">
                                <img alt="alt text" src={cv} />
                                <div>
                                    <h3>Size Özel Rapor</h3>
                                    <p>Tüm performans göstergelerinizin sonuçlarını ve markanız için en önemli istatistikleri, düzenli olarak rapor halinde sunarız.</p>
                                </div>
                            </div>
                            <div className="col-md-6 manage-google_item">
                                <img alt="alt text" src={maleMan} />
                                <div>
                                    <h3>Google İş Ortaklığı</h3>
                                    <p>Google iş ortaklığımız sayesinde size rekabet üstünlüğü sağlayacak en yeni Google Ads BETA özelliklerini deneyimleme şansına sahip olursunuz.</p>
                                </div>
                            </div>
                            <div className="col-md-6 manage-google_item">
                                <img alt="alt text" src={business} />
                                <div>
                                    <h3>Uygun Fiyatlandırmalar</h3>
                                    <p>Ücret, marka hedefleriniz ve çalışma kapsamına göre belirlenir. Bu doğrultuda aylık bir ön ödeme alınır. Bunun dışında istisnai ücretler talep edilmez.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                   
                    <div className="onboarding py60">
                        <div className="col-md-4">
                            <img alt="alt text" src={work} />
                            <h2>İşleyiş <br />
                                sürecimiz.</h2>
                            <p>Markalarımız için reklam <br />
                                sürecimizi nasıl <br />
                                sürdürdüğümüze göz atın.</p>
                        </div>
                        <div className="col-md-8">
                            <div className="hiring-tabs">
                                <input type="checkbox" name="onboarding" id="tab6" />
                                <input type="checkbox" name="onboarding" id="tab7" />
                                <input type="checkbox" name="onboarding" id="tab8" />
                                <input type="checkbox" name="onboarding" id="tab9" />
                                <input type="checkbox" name="onboarding" id="tab10" />
                                <input type="checkbox" name="onboarding" id="tab11" />

                                <div className="tab" id="tab6sec">
                                    <label htmlFor="tab6"> </label>
                                    <h3> <span>1</span> Keşif görüşmesi yapılır.</h3>
                                    <p>- Hedefleriniz, rekabet avantajlarınız, hedef kitleniz, performansınızı etkileyen faktörler gibi markanızı ve hizmet sunduğunuz sektörü olabildiğince iyi tanımak için öncelikle bir görüşme gerçekleştiriyoruz. </p>
                                    <div className="plus"></div>
                                </div>
                                <div className="tab" id="tab7sec">
                                    <label htmlFor="tab7"> </label>
                                    <h3> <span>2</span> Hesaplar denetlenerek fırsatlar <br />ortaya çıkarılır.</h3>
                                    <p>- Stratejilerimizi oluştururken yararlanacağımızı veriler doğrultusunda mevcut performansınızı analiz ediyoruz.</p>
                                    <div className="plus"></div>
                                </div>
                                <div className="tab" id="tab8sec">
                                    <label htmlFor="tab8"> </label>
                                    <h3> <span>3</span> Sonraki adımlara geçmek üzere bulgular <br />konuşulur.</h3>
                                    <p>- Büyüme fırsatlarını, ayrıntılı iş planını ve yatırım getirisi hesaplamalarını sunmak için yüz yüze veya görüntülü bir konuşma gerçekleştiriyoruz.</p>
                                    <div className="plus"></div>
                                </div>
                                <div className="tab" id="tab9sec">
                                    <label htmlFor="tab9"> </label>
                                    <h3> <span>4</span> Başlangıç stratejisi oluşturulur ve aksiyona geçilir.</h3>
                                    <p>- Audit sonucunda elde edilen bulgulara dayanan ilk 3 aylık öncelikli plana uygun ve yatırım geri dönüşü üzerinde olumlu etkiye sahip olacak eylemlere odaklanıyoruz.</p>
                                    <div className="plus"></div>
                                </div>
                                <div className="tab" id="tab10sec">
                                    <label htmlFor="tab10"> </label>
                                    <h3> <span>5</span> Optimizasyonlar ve aylık detaylı <br />raporlamalar sürdürülür.</h3>
                                    <p>- Performansı gözden geçirmek ve yeni fikirler tartışmak üzere sizinle haftalık olarak görüşüyoruz.</p>
                                    <div className="plus"></div>
                                </div>
                                <div className="tab" id="tab11sec">
                                    <label htmlFor="tab11"> </label>
                                    <h3> <span>6</span> Her üç ayda bir gelecek çeyreğe ilişkin <br />stratejik planlama oturumları gerçekleştirilir. </h3>
                                    <p>- Devam eden optimizasyonlar ve stratejik plan masaya yatırılarak sonraki döneme ilişkin ayrıntılı bir analiz değerlendirmesi yapılır, bütçenizin en yüksek değer sunacak şekilde yönetilmesi sağlanır.</p>
                                    <div className="plus"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="team">
                    <h2 style={{ textAlign: 'center', marginBottom: 120 }}> Size yardımcı olabilecek <br />Google Ads uzmanlarımız. </h2>
                    <Teams />
                </div>*/}
                <div className="hiring" style={{ marginTop: -20 }}>
                    <h2>Sıkça Sorulan Sorular</h2>
                    <SSS />
                </div>
                <Checkup />
                <div className="check-modal">
                    <span onClick={(e) => this.closeModal()}>X</span>
                    <h2 className="form-head">Formu doldurun.</h2>                               <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                    <div className="check-modal_container">
                        <form onSubmit={this.handleSubmit} >
                            <input type="text" name="text_204202" id="site" required  value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="Websitenizi girin." />
                            <input type="text" id="name" required name="text_775908" placeholder="Adınızı girin." />
                            <input type="email" id="email" required  name="text_532192" placeholder="E-posta adresinizi girin." />
                            <button  >GÖNDER</button>
                            <div className="status"></div>
                        </form>
                        <img alt="alt text" src={checkModal} />
                    </div>
                </div>
            </Layout >
            
        )
    }
}

export default Gads
